// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/shbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "*[data-v-6f9c97ce]{padding:0;margin:0;box-sizing:border-box}.bg[data-v-6f9c97ce]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;overflow-y:auto;background-size:100% 100%}.msg[data-v-6f9c97ce]{background:rgba(0,0,0,.2);margin:2%;width:96%;padding:.00667rem}.msg[data-v-6f9c97ce],.stationdata[data-v-6f9c97ce]{box-sizing:border-box}.stationdata[data-v-6f9c97ce]{list-style:none;padding:2%}.stationdata ul li[data-v-6f9c97ce]{border-bottom:.00133rem dashed #999;padding:.00667rem 0}.stationdata ul li[data-v-6f9c97ce],.stationdata ul li div[data-v-6f9c97ce]{display:-webkit-box;display:-webkit-flex;display:flex}.stationdata ul li div[data-v-6f9c97ce]{-webkit-box-align:center;-webkit-align-items:center;align-items:center}.stationdata ul li div img[data-v-6f9c97ce]{border-radius:50%;border:.00133rem solid #fff;width:3vh}.van-tabs__nav[data-v-6f9c97ce]{background-color:none!important}.imgbox img[data-v-6f9c97ce]{width:100%}.bigimg[data-v-6f9c97ce]{width:3vh!important;padding:.004rem;box-sizing:border-box}", ""]);
// Exports
module.exports = exports;
