
<template>
<div style="width:100%;height:100%;overflow-y: auto;">
<div class="bg">
    <p style="margin:0;padding:10px;color:rgb(203,192,101)" @click="showPicker=true"><van-icon name="location-o" /><span>当前站点:{{ stationname }}</span></p>

    <!-- 小气候站数据 -->
    <div class="stationdata msg">
        <p style="font-style:oblique;padding:5px;border-bottom:1px solid #999">更新时间:</p>
        <ul>
            <li v-for="(item,index) in datalist" :key="index">
<div v-for="(i,d) in item" :key="d" :style="{'width':item.length==2?'50%':'100%'}">
    <img :src="geturl(i.icon)" alt="" :class="i.icon=='sk_12'?'bigimg':i.icon=='sk_13'?'bigimg':''">
<span>{{   fengxiang(i.title,i.icon) }}:{{ data[i.key] }}{{ i.unit }}</span>
</div>
</li>
        </ul>

    </div>
    <!-- 天气实况 -->
    <div class="yubao msg">
<p style="padding:5px;border-bottom:1px solid #999">24小时天气实况</p>
<van-tabs background="rgba(0,0,0,0)" line-height="1" color="#1AAAFF" title-active-color="#fff" title-inactive-color="#fff" style="border-bottom:1px solid #999"  v-model="active" @change="changetabs">
  <van-tab v-for="item in tablist" :title="item" :key="item" :name="item"/>
</van-tabs>
<div ref="echart" style="width:100%;height:40vh"></div>
    </div>
    <!-- 实景数据 -->
    <div class="shijing msg">
<p style="padding:5px;box-sizing: border-box;display: flex;justify-content: space-between;border-bottom:1px solid #999">
    <span>实景数据</span><span style="font-style:oblique;">更新时间:{{ '2022/06/29 18:25' }}</span>
</p>
<div class="imgbox">
    <img src="@/assets/LOGO/泰来县.jpg" alt="">
</div>
    </div>
</div>
<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="stationlist"
    @cancel="showPicker = false"
    value-key="StationName"
    @confirm="enterstation"
    :default-index="3" 
    ref="picker"
  />
</van-popup>
</div>
</template>

<script>
import {Getfengxiang} from '@/utils/Getfengxiang.js'
import * as echarts from 'echarts';
import request from "@/utils/request.js";
import axios from 'axios'
let myChart 
export default {
  name: '',
  components: {},
  data () {
    return {
      data:{

      },
      stationno:'53892',
      stationname:'邯郸站',
      showPicker:false,
      stationlist:[],
      active:'温度',
        res:{
            wind:36
        },
        datalist:[
            [
                {
                  title:'气温',
                  icon:'sk_1',
                  key:'Wd1' , 
                  unit:'℃'
                },
                {
                    title:'湿度',
                  icon:'sk_2',
                  key:'Sd1',
                  unit:'%'
                }
            ],[
                {
                    title:'风向',
                    icon:'sk_4',
                    key:'Fx2m',
                    unit:'m/s'
                },
                {
                    title:'小时降雨',
                    icon:'sk_3',
                    key:'PRE_1h',
                    unit:'mm'
                }
            ],
            // [
            //     {
            //         title:"气压",
            //         icon:'sk_9',
            //         key:'pressure',
            //         unit:'hPa'
            //     }
            // ],
            [
                {
                    title:'10cm-40CM土壤温度',
                    icon:'sk_12',
                    key:'Dw40',
                    unit:'℃'
                }
            ],[
                {
                    title:' 10cm-40CM土壤湿度',
                    icon:'sk_13',
                    key:'TRHSL40',
                    unit:'%'
                }
            ]
        ],
        tablist:[
            '温度','湿度','降雨','风速风向','气压','土壤温度','土壤湿度'
        ],
        yslist:[]
    }
  },
  created () { },
   mounted () {
  
    this.getstation()
    this.getstationname()

   },
  methods: { 
    geturl(icon){
        try{
            return require('@/assets/images/'+icon+'.png')
        }catch(e){
            return require('@/assets/images/sk_9.png')
        }
    },
    changetabs(){
      console.log(this.active);
this.setecharts()
    },
    fengxiang(data,icon){
        if(icon=='sk_4'){
return Getfengxiang(this.data.Fx2m)
        }else{
            return data
        }
    },
   async setecharts(){
    if (myChart != null && myChart != "" && myChart != undefined) {
      myChart.dispose(); //销毁
      }
    let obj={
      '温度':'℃',
      "降雨":'mm',
      '湿度':"%",
      '风速风向':'m/s',
      '气压':"hPa"
    }
    let type={
      '温度':'Wd1',
      "降雨":'PRE_1h',
      '湿度':"Sd1",
      '风速风向':'Fs2m',
      // '气压':"pressure"
      '土壤温度':'Dw40',
      '土壤湿度':'TRHSL40',
    }
    let unit=obj[this.active],id='qiwen'
        let xdata=[],ydata=[], fxData=[]
        this.yslist.forEach(item => {
            xdata.push(item.ObservTimes.split('T')[1].split('.')[0]) 
            ydata.push(item[type[this.active]])
        });
        
        console.log(this.yslist);
        myChart=echarts.init(this.$refs.echart)
        let that=this
let option = {
  color: ['#5698c3'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (val, index) {
        var time = val[0].axisValue;
        if (that.active === '风速风向') {
          var a = val.map(it => ` ${it.seriesName}：${it.data} ${unit}<br />`).join(' ');
          var fx = Getfengxiang(series[0].fxData[val[0].dataIndex]);
          return time + '<br />' + a + ' 风向：'+ fx
        } else {
          var a = val.map(it => ` 温度:${it.data} ${unit}<br />`).join(' ');
          return time + '<br />' + a
        }
        
      }
    },
    toolbox: {
    show: true,
    feature: {
      magicType: { type: ['line', 'bar'] },
      saveAsImage: {}
    }
  },
  legend: {
    x: 'center',
    y: 'top',
    itemStyle: {
        color: '#5698c3',
        borderColor: '#5698c3',
        borderType:'solid',
        borderWidth: 1,
        borderRadius: 5
    },
    textStyle:{
      color:'#fff'
    }
  },
    grid: {
      top: 40,
      bottom: 50,
      left:50,
    },
  dataZoom: [
      //给x轴设置滚动条
      {
        height: 3,
        start: 0, //默认为0
        end: 100 - 1500 / 31, //默认为100
        type: "slider",
        show: true,
        xAxisIndex: [0,1],
        handleSize: 0, //滑动条的 左右2个滑动条的大小
        bottom: "0%",
        left: "90%", //左边的距离
        right: "90%",
        borderColor: "#000",
        fillerColor: "#01DFA5",
        borderRadius: 5,
        backgroundColor: "#33384b", //两边未选中的滑动条区域的颜色
        showDataShadow: false, //是否显示数据阴影 默认auto
        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
        realtime: true, //是否实时更新
        filterMode: "filter",
      },
      {
        type: "inside",
        show: true,
        xAxisIndex: [0,1],
        start: 0, //默认为1
        end: 100 - 1500 / 31, //默认为100
      },
  ],
    xAxis: [
      {
        type: 'category',
        axisLabel:{
          color:'#fff',
            showMinLabel:true
        },
        data: xdata 
      },
    ],
    yAxis: [
      {
        axisLine: {
            show:true,
          },
        type: 'value',
        // position: 'left',
        axisLabel: {
          color:'#fff',
          fontSize: 10,
          showMinLabel:true,
          formatter: '{value}' + unit
        }
      },
    ],
    series: { 
      data:ydata,
      type: 'line',
      name: this.active,
    }
  };
  if(this.active=='风速风向'){
    this.yslist.forEach(item => {

            fxData.push(item.Fx2m)
        });
   
    option.series =[
          {
            symbol: `image://${require("../../assets/images/fengxiangbiao.png")}`,
                symbolRotate: function(param, e) {
                  return fxData[e.dataIndex];
                },
                symbolSize: 15,
              label: {
                show: true,
                position: "top",
                color: "#4EBA99"
              },
              name: this.active,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top'
              },
              data: ydata,
              fxData:fxData
          }
        ]
  }else{
    fxData=[]
    option.series =[
    { 
      data:ydata,
      type: 'line',
      name: this.active,
    }
        ]
  }
        myChart.setOption(option);
        console.log(myChart);
    },
   async getstationname(){
      let res=await request({
        url:'/api/live_query/Get_Station',
      method: "get",
      params:{
        area: 1304,
        type: 3
      }})
    // let res={
    //   data:[]
    // }
    //   res.data=[
       
    // {
    //   "Id": 2,
    //   "Areacode": "130423",
    //   "Areanmae": "临漳",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.595",
    //   "lat": "36.2986",
    //   "High": "65.5",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "临漳站",
    //   "StationNo": "53773",
    //   "StationNum": "53773",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 3,
    //   "Areacode": "130426",
    //   "Areanmae": "涉县",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "113.6722",
    //   "lat": "36.5672",
    //   "High": "468.1",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "涉县站",
    //   "StationNo": "53886",
    //   "StationNum": "53886",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 4,
    //   "Areacode": "130481",
    //   "Areanmae": "武安",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.0997",
    //   "lat": "36.6658",
    //   "High": "281.8",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "武安站",
    //   "StationNo": "53890",
    //   "StationNum": "53890",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 5,
    //   "Areacode": "130471",
    //   "Areanmae": "邯郸",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.4644",
    //   "lat": "36.6247",
    //   "High": "70",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "邯郸站",
    //   "StationNo": "53892",
    //   "StationNum": "53892",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 6,
    //   "Areacode": "130435",
    //   "Areanmae": "曲周",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "115.005",
    //   "lat": "36.7889",
    //   "High": "37.9",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "曲周站",
    //   "StationNo": "53893",
    //   "StationNum": "53893",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 7,
    //   "Areacode": "130406",
    //   "Areanmae": "峰峰矿区",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.2094",
    //   "lat": "36.4039",
    //   "High": "145",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "峰峰站",
    //   "StationNo": "53894",
    //   "StationNum": "53894",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 8,
    //   "Areacode": "130408",
    //   "Areanmae": "永年",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.4558",
    //   "lat": "36.7475",
    //   "High": "111.2",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "永年站",
    //   "StationNo": "53895",
    //   "StationNum": "53895",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 9,
    //   "Areacode": "130434",
    //   "Areanmae": "魏县",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.9494",
    //   "lat": "36.3278",
    //   "High": "51.6",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "魏县站",
    //   "StationNo": "53896",
    //   "StationNum": "53896",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 10,
    //   "Areacode": "130427",
    //   "Areanmae": "磁县",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.3428",
    //   "lat": "36.4006",
    //   "High": "87.4",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "磁县站",
    //   "StationNo": "53897",
    //   "StationNum": "53897",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 11,
    //   "Areacode": "130432",
    //   "Areanmae": "广平",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.9631",
    //   "lat": "36.4825",
    //   "High": "45.8",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "广平站",
    //   "StationNo": "53899",
    //   "StationNum": "53899",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 12,
    //   "Areacode": "130407",
    //   "Areanmae": "肥乡",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.8075",
    //   "lat": "36.5408",
    //   "High": "50.7",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "肥乡站",
    //   "StationNo": "53980",
    //   "StationNum": "53980",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 13,
    //   "Areacode": "130424",
    //   "Areanmae": "成安",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.6469",
    //   "lat": "36.4464",
    //   "High": "58.4",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "成安站",
    //   "StationNo": "53996",
    //   "StationNum": "53996",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 14,
    //   "Areacode": "130431",
    //   "Areanmae": "鸡泽",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "114.8728",
    //   "lat": "36.8903",
    //   "High": "36.6",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "鸡泽站",
    //   "StationNo": "54640",
    //   "StationNum": "54640",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 15,
    //   "Areacode": "130425",
    //   "Areanmae": "大名",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "115.1833",
    //   "lat": "36.3058",
    //   "High": "41.9",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "大名站",
    //   "StationNo": "54804",
    //   "StationNum": "54804",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 17,
    //   "Areacode": "130433",
    //   "Areanmae": "馆陶",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "115.2903",
    //   "lat": "36.5489",
    //   "High": "41.9",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "馆陶站",
    //   "StationNo": "54809",
    //   "StationNum": "54809",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // },
    // {
    //   "Id": 16,
    //   "Areacode": "130430",
    //   "Areanmae": "邱县",
    //   "Attributionid": null,
    //   "Devicetype": null,
    //   "Type": 3,
    //   "state": null,
    //   "Lng": "115.2078",
    //   "lat": "36.81",
    //   "High": "39",
    //   "Installationtime": "2023-09-09T20:12:23",
    //   "StationName": "邱县站",
    //   "StationNo": "54820",
    //   "StationNum": "54820",
    //   "config": null,
    //   "useMan": null,
    //   "charger": null,
    //   "linktel": null,
    //   "regionconfig": null,
    //   "isautosend": null,
    //   "Lasttime": null,
    //   "isOnline": null,
    //   "pointlevel": null
    // }
  

    //   ]
this.stationlist=res.data
    },

    getstation(){
      let params={stationid:this.stationno,_:new Date().getTime()}
    request({
      url: "/api/Agroclimate/GetList",
      method: "GET",
      params
    }).then(res=>{
this.data=res.data.data[res.data.data.length-1]
this.yslist=res.data.data
this.setecharts()
     })
    },
    enterstation(){
let stationdata=this.$refs.picker.getValues()[0]
console.log(stationdata);
this.satationname=stationdata.StationName
this.stationno=stationdata.StationNo
this.showPicker=false
this.getstation()
    }
  },
  computed: {}
}
</script>
<style scoped lang='scss'>
*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}
.bg{
    background-image: url('~@/assets/images/shbg.jpg');
    width: 100%;
    height:100%;
    overflow-y: auto;
    background-size:100% 100% ;
}
.msg{
    background:rgba(0,0,0,0.2);
    margin:2%;
    width:96%;
    padding: 5px;
    box-sizing: border-box;
}
.stationdata{
    list-style: none;
    padding:2%;
    box-sizing: border-box;
ul{
    li{
        display: flex;
        border-bottom:1px dashed #999;
        padding:5px 0;
        div{
            display: flex;
            align-items: center;
            img{
              border-radius:50% ;
              border: 1px solid #fff;
                width: 3vh;
            }
        }
    }
}
}
.van-tabs__nav{
    background-color: none !important;
}
.imgbox{
    img{
        width:100%
    }
}
.bigimg{
  width:3vh !important;
  padding:3px;
  box-sizing: border-box;
}
</style>
